import { Box } from '@mantine/core';
import classes from '@/style/Gradient.module.css';

interface GradientProps {
  color1: string;
  color2: string;
  color1Percent: number;
  color2Percent: number;
  h: string;
  w: string;
  [key: string]: unknown;
}

export const Gradient = ({ color1, color2, color1Percent, color2Percent, h, w, ...props }: GradientProps) => {
  const radialGradient = `linear-gradient(360deg, ${color1} ${color1Percent}%, ${color2} ${color2Percent}%)`;
  return <Box bg={radialGradient} h={h} w={w} className={classes.box} {...props}></Box>;
};
