import { type FC } from 'react';
import { Flex, Title, Text, Stack, rem, useMantineTheme, AspectRatio } from '@mantine/core';
import classes from '@/style/FeatureSection.module.css';
import { Gradient } from '../Gradient';

interface FeatureSection {
  id: number;
  title: string;
  text1: string;
  text2?: string;
  image: string;
  titleStyle?: React.CSSProperties;
  isVideo?: boolean;
}

interface FeatureSectionProps {
  feature: FeatureSection;
  hideGradient?: boolean;
}

const FeatureSection: FC<FeatureSectionProps> = ({ feature, hideGradient }) => {
  const theme = useMantineTheme();
  const isEven = feature.id % 2 === 0;
  const gradientStyle = isEven ? { left: '-1rem', rotate: '-25deg' } : { right: '-1rem', rotate: '25deg' };
  return (
    <Flex
      align={{ base: 'start', sm: 'center' }}
      direction={{ base: 'column-reverse', sm: isEven ? 'row-reverse' : 'row' }}
      gap={{ base: 'xl', sm: rem(50), md: rem(32) }}
      className={classes['feature-container']}
    >
      {!hideGradient && (
        <Gradient
          h={rem(600)}
          w={rem(350)}
          color1="#1F7377"
          color1Percent={36}
          color2={theme.other.brandColors.sunray}
          color2Percent={76}
          style={{ ...gradientStyle }}
        />
      )}
      <Stack
        className={classes['feature-text']}
        w={{ base: '100%', sm: '50%' }}
        maw={rem(670)}
        ta={{ base: 'center', sm: 'left' }}
      >
        <Title
          fz={{ base: rem(32), md: rem(38) }}
          order={1}
          c="white"
          fw={700}
          lh={1.2}
          className={classes['feature-title']}
        >
          {feature.title}
        </Title>
        <Text fz={{ base: rem(18), md: rem(20) }} c={theme.other.colors.textLightGray} lh={1.5} fw={500}>
          {feature.text1}
        </Text>
        {feature.text2 && (
          <Text fz={{ base: rem(18), md: rem(20) }} c={theme.other.colors.textLightGray} lh={1.5} fw={500}>
            {feature.text2}
          </Text>
        )}
      </Stack>
      <Stack
        w={{ base: '100%', sm: '50%' }}
        mx={{ base: 'auto', sm: 0 }}
        className={classes['feature-media']}
        data-alignment={isEven ? 'start' : 'end'}
      >
        {feature.isVideo ? (
          <AspectRatio
            ratio={16 / 9}
            maw={{ base: '100%', sm: rem(425) }}
            mah={{ base: 'auto', sm: rem(375) }}
            style={{
              borderRadius: 'var(--mantine-radius-xl)',
            }}
          >
            <video
              autoPlay
              loop
              muted
              playsInline
              className={classes['feature-image']}
              style={{
                width: '100%',
                height: '100%',
                position: 'relative',
                zIndex: 'inherit',
                borderRadius: 'inherit',
                objectFit: 'fill',
              }}
            >
              <source src={feature.image} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </AspectRatio>
        ) : (
          <img
            style={{
              borderRadius: 'var(--mantine-radius-xl)',
              height: '100%',
              width: '100%',
              maxHeight: rem(375),
              maxWidth: rem(425),
            }}
            src={feature.image}
            alt={feature.title}
            className={classes['feature-image']}
          />
        )}
      </Stack>
    </Flex>
  );
};

export default FeatureSection;
