import { useCombobox, Combobox, TextInput, Text, Stack, Loader } from '@mantine/core';
import { useEffect, useState } from 'react';
import classes from '@/style/ClaimPodcastSearch.module.css';
import type { PodcastSearchResult } from '@/types';
import { IconSearch } from '@tabler/icons-react';
import { useDebounceFetcher } from 'remix-utils/use-debounce-fetcher';

interface PodcastFinderProps {
  setPodcast: (podcast: PodcastSearchResult | undefined) => void;
  error: string | undefined;
}

interface FetcherResult {
  podcasts: PodcastSearchResult[] | [];
}

export default function PodcastFinder({ setPodcast, error }: PodcastFinderProps) {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const fetcher = useDebounceFetcher<FetcherResult>();
  const [loading, setLoading] = useState(false);
  const data = fetcher?.data || { podcasts: [] };
  const podcasts = data.podcasts || [];

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: (eventSource) => {
      if (eventSource === 'keyboard') {
        combobox.selectActiveOption();
      } else {
        combobox.updateSelectedOptionIndex('active');
      }
    },
  });

  const options = podcasts.map((result) => (
    <Combobox.Option
      key={result.feed_url}
      value={result.title}
      className={classes['dropdown-option']}
      active={result.title === value}
    >
      {result.title} ({result.feed_url})
    </Combobox.Option>
  ));

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPodcast(undefined);
    setValue(e.currentTarget.value);
    if (e.currentTarget.value) {
      fetcher.submit({ q: e.currentTarget.value }, { method: 'GET', debounceTimeout: 250 });
      combobox.openDropdown();
      combobox.updateSelectedOptionIndex();
    }
  };

  useEffect(() => {
    setLoading(fetcher.state === 'loading');
  }, [fetcher.state]);

  const placeholderText = 'Enter your podcast title, author email, or feed URL';

  return (
    <Combobox
      onOptionSubmit={(optionValue) => {
        setValue(optionValue);
        setPodcast(podcasts.find((result) => result.title === optionValue));
        combobox.closeDropdown();
      }}
      middlewares={{ flip: false }}
      store={combobox}
    >
      <fetcher.Form className={classes['form-input']}>
        <Combobox.Target>
          <Stack className={classes['claim-podcast-input-container']}>
            <TextInput
              classNames={{
                wrapper: classes['dropdown-input'],
                input: classes['dropdown-input-field'],
              }}
              leftSection={<IconSearch size={18} color="white" />}
              placeholder={isFocused ? '' : placeholderText}
              size="md"
              value={value}
              error={error}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              onChange={handleOnChange}
              onClick={() => combobox.openDropdown()}
              onFocus={() => {
                setIsFocused(true);
                combobox.openDropdown();
              }}
              onBlur={(e) => {
                if (!e.target.value) setIsFocused(false);
                combobox.closeDropdown();
              }}
            />
            <Text
              size="xs"
              className={`${classes['claim-podcast-placeholder']} ${
                isFocused || value ? classes['claim-podcast-placeholder-focused'] : ''
              }`}
            >
              {placeholderText}
            </Text>
          </Stack>
        </Combobox.Target>

        <Combobox.Dropdown hidden={value.trim().length === 0} className={classes['dropdown-menu']}>
          <Combobox.Options>
            {loading && (
              <Combobox.Empty>
                <Loader size="sm" />
              </Combobox.Empty>
            )}
            {podcasts.length === 0 ? <Combobox.Empty>No results found</Combobox.Empty> : options}
          </Combobox.Options>
        </Combobox.Dropdown>
      </fetcher.Form>
    </Combobox>
  );
}
